.app__navbar {
    width: 100%;
    display: flex;
background-color: #00000000;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;

    height: 10% !important;
  

}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}

.app__navbar-logo img {
    width: 80px;
    border-radius: 50%;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
position: relative;
top: 7px;
color: #fff;
text-decoration: none;
    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}



.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a {
    margin: 0 4rem;
    text-decoration: none;
    transition: .5s ease;
}



.app__navbar-login div {
    width: 1px;
    height: 0px;
    background: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
   
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
 
    transition: .5s ease;
    flex-direction: column;
    z-index: 5;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center  !important;
}

.app__navbar-smallscreen_overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    padding: inherit !important;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background-color: rgb(245, 245, 245) !important; /* Use rgba color with reduced opacity */
    backdrop-filter: blur(10px); /* Apply a blur effect to create the glass-like appearance */
    -webkit-backdrop-filter: blur(10px); /* Safari support for backdrop-filter */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
    z-index: -1; /* Place the overlay behind the content */
}


.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color:#000000;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;

 
font-weight: 900;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links .li {
    margin: rem;
    cursor: pointer;
    color: #000000;
    text-decoration: none !important;
    font-size: 1rem;
    text-align: start;
  
}



@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 110px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
        text-decoration: none;
        color: #000000;
        padding: inherit !important;
    }

    .app__navbar-smallscreen {
        display: flex; padding: inherit !important;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-login {
      
    }

    .app__navbar-logo img {
        width: 60px;
    }
}

.nav-link {
    color: #000000 !important;
    text-decoration: none !important;
}

.a{
    color: #000000 !important;
    text-decoration: none !important;
    font-size: 16px;
    font-weight: 700;
}
.back_of{
    padding: 10px;
    color: #fff;
    background-color: rgba(0, 255, 255, 0);
    transition: background-color 0.3s ease;
}


.back_of:hover {
   
    color: rgb(255, 255, 255);
    backdrop-filter: blur(5px);

  }

  .hr{
    color: #fff;
  }









  /* Navbars.css */
.app__navbar {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    background-color: #fff !important;
    transition: background-color 0.3s ease-in-out !important;
    z-index: 100 !important;
  }
  
  .app__navbar-scroll {
    background-color: rgba(247, 247, 247, 0.38) !important; /* Use rgba color with reduced opacity */
    backdrop-filter: blur(10px); /* Apply a blur effect to create the glass-like appearance */
    -webkit-backdrop-filter: blur(10px); /* Safari support for backdrop-filter */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
  }
  
  
  .app__navbar-logo img {
    max-height: 100px !important; /* Adjust as needed */
    transition: max-height 0.3s ease-in-out !important;
  }
  
  .app__navbar-scroll .app__navbar-logo img {
    max-height: 60px !important; 
  }
  
  /* Add your other styles here */
  



