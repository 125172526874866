@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

:root {
	--mainColor:transparent;
	--mainColors: #ffffff;
	--mainColorLight: #ffd240;
	--secondaryColor: #ffc62b;
	--textColor: #000000;
}

.head{
   background-color: #ffffff !important;

  
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height:55px;
	padding: 0 1rem;
	background-color: var(--mainColor);
	color: var(--textColor);
    z-index: inherit !important;
}

nav .a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none !important;
	overflow: hidden;
	position: relative;
	display: inline-block;
 
	
}

.a::before,
.a::after {
 content: '';
  position: absolute;
  width: 100%;
  left: 0;
}

.a::before {
 
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.a::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  
}

.a:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

.a:hover::after {
  transform: translate3d(0, 0, 0);
}

.a span {
  display: inline-block;
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

a:hover span {
  transform: translate3d(-200%, 0, 0);
}

header .nav-btn {
	padding: 4px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor) !important;
	visibility: hidden;
	opacity: 0;
	font-size: 1.1rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
        z-index: 100000000000000 !important;
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2rem;
		background-color: var(--mainColors);
		transition: 1s;
		}

		header .responsive_nav {
		transform: translateY(100vh);
        z-index: 100000000000000 !important;
	
	}

     	nav .nav-close-btn {
		position: absolute;
		top: 2.5rem;
		right: 2rem;
	}

	    nav a {
		font-size: 1.5rem;
	}
}
.font_nav_text{
    font-size: 24px;
    font-weight: bold !important;
	position: relative;
	bottom: 120px;
}

@media screen and (min-width: 1000px) {
  
	.font_nav_text{
		font-size: 16px;
		font-weight: 500 !important;
		position: relative;
		bottom: 0px;
	}
	}
.font_nav_texts{
    font-size: 30px;
    font-weight: 600 !important;
	transition: all 0.3s !important;
}





.navbar_logo{
	width: 50%;
	position: relative;
	left: 20px;
	top:-5px;
}

@media screen and (min-width: 1000px) {
  
	.navbar_logo{
		width: 55%;
	}
	}











	.text_head{
		color: #000000 !important;
	}
	.text_head :hover{
		color: #00643e !important;
	}














	.nav-link {
		color: #000 !important;
	}



	.navbar-nav{
		width: max-content !important;
	}


	.start_nav_text{
		text-align: start !important;
		align-items: start !important;
	
	}