

.swiper {
  width: 100%;
  
  
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;
  height: 340px;
}

.swiper-slide img {
 
  width: 100%;
}





















































.swiper-nav-btns{
 text-align: center;
 position: relative;
 top: -10px;
}




.swiper_button_css{
  background: transparent !important;
  border: none !important;
  
}



.swiper_button_css:focus {
   outline: none !important;
   outline:  none !important; 
}

.swiper_icons{
  color: rgb(33, 104, 60) !important;
  font-size: 40px !important;
  font-weight: 300;
}





































































































































