.nft{
    user-select:none;
    max-width: 400px;
    margin: 5rem auto;
    color: #000 !important;
    border: 1px solid #ffffff13 ;
   height: 77%;
    background-color: #ffffff;
    background: linear-gradient(0deg, rgb(255, 255, 255) 20%, rgb(255, 255, 255)  100%);
    border-radius: .7rem;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    overflow: hidden;
    transition: .5s all;
    hr{
      width: 100%;
      border: none;
      border-bottom: 1px solid rgb(255, 255, 255);
      margin-top: 0;
    }
    ins{
      text-decoration: none;
    }
    .main{
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem;
      .tokenImage{
        border-radius: .5rem;
        max-width: 100%;
        height: 250px;
        object-fit: cover;
      }
      .description{
        margin: .5rem 0;
        color: #000000d2;
        text-align: center;
      }
      .tokenInfo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price{
          display: flex;
          align-items: center;
          color: #ee83e5;
          font-weight: 700;
          ins{
            margin-left: -.3rem;
            margin-right: .5rem;
          }
        }
        .duration{
          display: flex;
          align-items: center;
          color: #a89ec9;
          margin-right: .2rem;
          ins{
            margin: .5rem;
            margin-bottom: .4rem;
          }
        }
      }
      .creator{
        display: flex;
        align-items: center;
        margin-top: .2rem;
        margin-bottom: -.3rem;
        ins{
          color: #a89ec9;
          text-decoration: none;
        }
        .wrapper{
          display: flex;
          align-items: center;
          border: 1px solid #ffffff22;
          padding: .3rem;
          margin: 0;
          margin-right: .5rem;
          border-radius: 100%;
          box-shadow: inset 0 0 0 4px #581919aa;
          img{
            border-radius: 100%;
            border: 1px solid #ffffff22;
            width: 2rem;
            height: 2rem;
            object-fit: cover;
            margin: 0;
          }
        }
      }
    }
    ::before{

    }
    &:hover{
      border: 1px solid #ffffff44;
     
     
    }
  }
  
  .bg{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1{
      font-size: 20rem;
      filter: opacity(0.5);
    }
  }


  .men{
    color: #000000;
    text-align:start;
  }