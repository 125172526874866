*{
  font-family: 'Poppins', sans-serif !important;
   
}


body{
  background: #ffffff !important;
}
.background_hero{
    background-image: url('./Component//image/hombgs.jpg');
    background-color: rgba(169, 169, 169, 0.6); /* 50% opacity black color overlay */
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
}
.background_hero_ed{
  background-image: url('./Component//image/geted.jpeg');
  background-color: rgba(169, 169, 169, 0.6); /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}


.pone{
  color: #fffffff6;
  font-weight: 500;
  font-size: 22px;
  text-align: center;  text-shadow: 1px 1px 1px #000, 
  3px 3px 5px rgb(0, 0, 0); 
}




         
.btn-grad {
  background-image: linear-gradient(to right, #3cb1a0 0%, #3CD3AD  51%, #4CB8C4  100%);
  margin: 13px;
  padding: 13px 8px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(255, 255, 255); 
  border: none !important;
  font-weight: 700;           
font-size: 14px !important;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media screen and (min-width: 1000px) {
  
          
.btn-grad {
  background-image: linear-gradient(to right, #3cb1a0 0%, #3CD3AD  51%, #4CB8C4  100%);
  margin: 10px;
  padding: 10px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(255, 255, 255); 
  border: none !important;
  font-weight: 700;           
  font-size: 16px !important;
  border-radius: 10px;
  display: block;
}
  }































.center{
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}










.btn-grads {
  background-image: linear-gradient(to right, #4cb8c41f 0%, #3cd3ad0a  51%, #3cb1a0  100%);
  margin: 10px;
  padding: 10px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0); 
  border: none !important;
  font-weight: 700;           
  font-size: 10px !important;
  border-radius: 40px;
  display: block;
}

@media screen and (min-width: 1000px) {
  
          
  .btn-grads {
    background-image: linear-gradient(to right, #4cb8c41f 0%, #3cd3ad0a  51%, #3cb1a0  100%);
    margin: 10px;
    padding: 10px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: rgb(0, 0, 0); 
    border: none !important;
    font-weight: 700;           
    font-size: 16px !important;
    border-radius: 40px;
    display: block;
  }
    }
  

.btn-grads:hover {
  background-position: right center; /* change the direction of the change here */
  color: #000000;
  text-decoration: none;
}




.under_back{
  background: transparent;
}



.under_backs{
  background: rgb(255, 255, 255);
}




.under_backk{
  background: rgb(255, 255, 255);
}



.happy_image_patient {
  background-image: url('./Component//image/patient.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  backface-visibility: visible;
  background-attachment: fixed;
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
}

@media (max-width: 768px) {
  /* Apply mobile-specific styles here */
  .happy_image_patient {
    background-size: contain;
    background-attachment: fixed;
    background-color: transparent; /* Remove the overlay on mobile */
  }
}




.tele_head{
  font-size: 25px;
}

@media screen and (min-width: 1000px) {
  
  .tele_head{
    font-size: 40px;
  }
  }




  

.tele_under{
  font-size: 14px;
}

@media screen and (min-width: 1000px) {
  
  .tele_under{
    font-size: 25px;
  }
  }


.faq_head{
font-size: 20px !important;
color: #000000 !important;
font-weight: 500 !important;
}
@media screen and (min-width: 1000px) {
  
  .faq_head{
    font-size: 25px !important;
    color: #000000 !important;
    font-weight: 700 !important;
  }
  }
.faq_under{
  font-size: 14px !important;
color: #000000 !important;
font-weight: 300 !important;
text-align: start !important;
}

@media screen and (min-width: 1000px) {
  
  .faq_under{
    font-size: 17px !important;
  color: #000000 !important;
  font-weight: 300 !important;
  text-align: start !important;
  }
  }

  
.term_image_patient{
  background-image: url('./Component//image/term.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  backface-visibility: visible;
  background-attachment: fixed;

  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
}

.term_image_privacy{
  background-image: url('./Component//image/privacy.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  backface-visibility: visible;
  background-attachment: fixed;
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
}





.term_white{
  font-size: 16px;
}

@media screen and (min-width: 1000px) {
  
  .term_white{
    font-size: 26px;
  }
  }


  .term_whitex{
    font-size: 14px;
  }
  
  @media screen and (min-width: 1000px) {
    
    .term_whitex{
      font-size: 20px;
    }
    }
  



  .back_refund{
    background-color: #388b77ce !important;
  }



  .background_about{
    background-image: url('./Component//image/aboutus.jpg');
    background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
}


.term_white_about{
  font-size: 22px;
}

@media screen and (min-width: 1000px) {
  
  .term_white_about{
    font-size: 44px;
  }
  }






  
.term_white_exe{
  font-size: 25px;
}

@media screen and (min-width: 1000px) {
  
  .term_white_exe{
    font-size: 45px;
  }
  }


  .back_exec_card{
    background-color: #fffffff1 !important;
   
  }
 
  .icon_hov{
    background-color: #fffffff1 !important;
    transition: transform .2s !important; /* Animation */
  }
  .icon_hov:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }


  .background_hair{
    background-image: url('./Component//image/hair.jpeg');
    background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
}









  .background_product_card{
    background-color: transparent !important;
    color: #000000 !important;
    border: 1px solid transparent !important;
  }

  .background_product_card_male{
    background-color: #00000085 !important;
    border: 1px solid #41414125 !important;
  }
  .background_sleep{
    background-image: url('./Component//image/sleephero.jpeg');
    background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
}




.background_vitamin{
  background-image: url('./Component//image/vitamin.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}




.background_malesex{
  background-image: url('./Component//image/malesex.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.background_malesexc{
  background-image: url('./Component//image/bgmales.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}



.background_femaless{
  background-image: url('./Component//image/bgwomtp.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}





.background_Testosterone{
  background-image: url('./Component//image/Testosterone.jpeg');
  background-color: rgba(169, 169, 169, 0.6); /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.testos_card_three{
  background-color: #3cb19f8c !important;
  color: #fff !important;
  text-align: center !important;
  
}





.dropdown-toggle::after {
 display: none !important;
}


.cards_male_sex{
  background-color: #000000d8 !important;
}



.background_femalesex{
  background-image: url('./Component//image/dreambg.jpg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.background_GrowthHormone{
  background-image: url('./Component//image/growth.jpeg');
  background-color: rgba(169, 169, 169, 0.6); /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}



.background_AllHormone{
  background-image: url('./Component//image/allhormone.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.background_AllWeightLossProgram{
  background-image: url('./Component//image/allweightloss.jpeg');
  background-color: rgba(25, 25, 25, 0.6); /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}



.background_review{
  background-image: url('./Component//image/rev.jpg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}




.background_contactus{
  background-image: url('./Component//image/contact.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}




.background_WayLess{
  background-image: url('./Component//image/WayLess.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}














.btn-gradss {
  background-image: linear-gradient(to right, #4cb8c41f 0%, #3cd3ad0a  51%, #3cb1a0  100%);
  margin: 1px;
  padding: 1px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0); 
  border: none !important;
  font-weight: 500;           
font-size: 10px !important;
  border-radius: 50px;

  display: block;
}

.btn-gradss:hover {
  background-position: right center; /* change the direction of the change here */
  color: #000000;
  text-decoration: none;
}


.magic1_image_patient{
  background-image: url('./Component//image/magic1.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  backface-visibility: visible;
  background-attachment: fixed;

  background-color: rgba(169, 169, 169, 0.6); /* 50% opacity black color overlay */
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
}

.background_WayTrain{
  background-image: url('./Component//image/waytain.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  backface-visibility: visible;
  background-attachment: fixed;
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
    background-repeat: no-repeat;
    background-position: center center;
}





.background_Diet{
  background-image: url('./Component//image/diet.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  backface-visibility: visible;
  background-attachment: fixed;
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
    background-repeat: no-repeat;
    background-position: center center;
}
.background_Burn{
  background-image: url('./Component//image/burn.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  backface-visibility: visible;
  background-attachment: fixed;
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
    background-repeat: no-repeat;
    background-position: center center;
}




















         
.btn-gradpo {
  background-image: linear-gradient(to right, #fdfdfd 0%, #ffffff  51%, #affcff65  100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0) !important;            
  border-radius: 10px;
  display: block;
}

.btn-gradpo:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.main_desktop_navbar{
background: #ffffff !important;
height: 150px;
}

.footer_back{
  background:#282828 !important;
}

.footer_backs{
  background:#000000 !important;
}

.backOFhero{
  background-color: #eaecec00 !important;
  color: #000000 !important;
  border-radius: 10px;
  
}





.background_dream_cream{
  background-image: url('./Component//image/dreamcreambg.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}


.background_dream_creamtwo{
  background-image: url('./Component//image/bgwomt.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}




.background_comb_med_one_sil{
  background-image: url('./Component/image/silden.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}



.background_comb_med_one_tad{
  background-image: url('./Component/image/tada.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}





.background_comb_med_one_combtwo{
  background-image: url('./Component/image/sildpap.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}


.background_comb_med_comthree{
  background-image: url('./Component/image/sidamo.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}


.background_comb_med_one{
    background-image: url('./Component/image/bgCombmedicineone.jpeg');
    background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
}

.background_comb_med_comfou{
  background-image: url('./Component/image/combfo.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}
.background_comb_med_combone{
  background-image: url('./Component/image/comboone.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}


.background_comb_med_two{
  background-image: url('./Component/image/medtwo.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.background_vitamins{
  background-image: url('./Component/image/bgvit.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;

}

.background_AllWeightLossPrograms{
  background-image: url('./Component/image/bgwei.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}




.background_AllWeightLossProgramss{
  background-image: url('./Component/image/mobwe.png');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}




















         
.btn-grado {
  background-image: linear-gradient(to right, #3cb1a0 0%, #3CD3AD  51%, #4CB8C4  100%);
  margin: 10px;
  padding: 10px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0); 
  border: none !important;
  font-weight: 700;           
font-size: 17px !important;
  border-radius: 5px;
  display: block;
}

.btn-grado:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media screen and (min-width: 1000px) {
  
          
.btn-grado {
  background-image: linear-gradient(to right, #3cb1a0 0%, #3CD3AD  51%, #4CB8C4  100%);
  margin: 10px;
  padding: 10px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0); 
  border: none !important;
  font-weight: 700;           
  font-size: 16px !important;
  border-radius: 10px;
  display: block;
}
  }






  
.background_comb_med_three{
  background-image: url('./Component/image/PEMaxTadalafil-Apomorphine.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}











  
.background_comb_med_four{
  background-image: url('./Component/image/med44.jpeg');
  background-color: rgba(169, 169, 169, 0.6);  /* 50% opacity black color overlay */
background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}


.back_of_top_cards_hero{
  background-color: #2580978c !important;
  width: 100% !important;
}


.products{
width: 100%;
height: 100%;


}



@media screen and (min-width: 1000px) {
  
  .products{
    width: 700px;
    height: 600px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: -webkit-inline-box !important;

    border: 2px solid #fff !important;
  }
    }
  
  
  













 

         
.btn-gradpohome {
  background-image: linear-gradient(to right, #fdfdfd 0%, #ffffff  51%, #affcff65  100%);
  margin: 1px;
  padding: 10px 45px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0) !important;            
  border-radius: 10px;
  display: block;
  font-size: 14px;
  font-weight: 700 !important;
}


@media screen and (min-width: 1400px) {
  
          
.btn-gradpohome {
  background-image: linear-gradient(to right, #fdfdfd 0%, #ffffff  51%, #affcff65  100%);
  margin: 10px;
  padding: 15px 45px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  font-size: 12px;
  color: rgb(0, 0, 0) !important;            
  border-radius: 10px;
  display: block;
}
    }
  

.btn-gradpo:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
   

.ps{
  text-shadow: 1px 1px 1px #000, 
  3px 3px 5px rgb(0, 0, 0); 
}





















.divider{
  color: #000000 !important;

}


@media screen and (min-width: 1000px) {
  
          
.divider{
    color: #ffffff00 !important;
    display: none !important;

}
    }



.rs-btn{
  background: transparent !important;
}    


.back_new{
  text-decoration: none !important;
  color: #000 !important;
}
.back_new:hover{
   background: #fff !important;
  color: #000 !important;
}

.center_thumbb{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  position: relative;

}





.centerall{
  display: flex !important; /* Use flexbox for centering */
  justify-content: center !important; /* Horizontally center content */
  align-items: center !important; /* Vertically center content */
text-align: center;
}


.back_of_grey{
  background-color: #5656562e !important;
}

.margin_container{
  margin-left: 20px;
  margin-right: 20px;
}

@media screen and (min-width: 1000px) {
  
          
  .margin_container{
    margin-left: 250px;
    margin-right: 250px;
  }
  
      }

      .margin_containert{
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
      }
      
      @media screen and (min-width: 1000px) {
        
                
        .margin_containert{
          margin-left: 50px;
          margin-right: 50px;
          text-align: center;
        }
        
            }




            .margin_containerthree{
              margin-left: 40px;
              margin-right: 40px;
            }

            
@media screen and (min-width: 1000px) {
  
          
  .margin_containerthree{
    margin-left: 100px;
    margin-right: 100px;
    position: relative;
    bottom: 60px;
    left: 140px;
  }
  
      }

     


.head_of_products{
  font-size: 25px;
  font-weight: 600;
}
@media screen and (min-width: 1000px) {
        
                
  .head_of_products{
    font-size: 40px;
    font-weight: 700;
  }  
      }

      .head_of_products_text{
        font-size: 18px;
          font-weight: 400;
      }
      @media screen and (min-width: 1000px) {
              
                      
        .head_of_products_text{
          font-size: 15px;
          font-weight: 400;
        }  
            }

            .center_thumbs{
              justify-content: center;
              align-items: center;
              text-align: center;
              display: flex;
            
            position: relative;
            left:10px;
            
             
            }

            @media screen and (min-width: 1000px) {
              
                      
              .center_thumbs{
                justify-content: center;
                align-items: center;
                text-align: center;
                display: flex;
              
              position: relative;
              left:70px;
              
               
              }
                  }


                  .center_thumb{
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    display: flex;
                    position: relative;
                  right: 10px;
                  }
                  @media screen and (min-width: 1000px) {
              
                      
                    .center_thumb{
                      justify-content: center;
                      align-items: center;
                      text-align: center;
                      display: flex;
                      position: relative;
                    right: 150px;
                    }
                        }
      .certified_doctor{
position: relative;
top: 10px;
      }

      @media screen and (min-width: 1000px) {
              
        .certified_doctor{
          position: relative;
          top: 100px;
        }
            }


     .footer_logo_top{
      width: 100%;
     }       


     @media screen and (min-width: 1000px) {
              
      .footer_logo_top{
        width: 35%;
       } 
          }


          
     .footer_text_top{
      font-size: 15px;
     }       


     @media screen and (min-width: 1000px) {
              
      .footer_text_top{
       font-size: 20px;
       } 
          }
          .hone{
            color: #ffffff;
            font-weight: 700;
            font-size: 30px;
          text-align: center !important;
            text-shadow: 1px 1px 1px #000, 
            3px 3px 5px rgb(0, 0, 0); 
          }
          @media screen and (min-width: 1000px) {
              
            .hone{
              color: #ffffff;
              font-weight: 900;
              text-align: start !important;
              font-size: 70px;
             line-height: 40px !important;
              text-shadow: 1px 1px 1px #000, 
              3px 3px 5px rgb(0, 0, 0); 
            }



            
                }




                .honemf {
                  color: #ffffff;
                  font-weight: 700;
                  font-size: 30px;
                text-align: center !important;
                  text-shadow: 1px 1px 1px #000, 
                  3px 3px 5px rgb(0, 0, 0); 
                }
                @media screen and (min-width: 1000px) {
                    
                  .honemf {
                    color: #ffffff;
                    font-weight: 900;
                    text-align: start !important;
                    font-size: 70px;
                   line-height: 80px !important;
                    text-shadow: 1px 1px 1px #000, 
                    3px 3px 5px rgb(0, 0, 0); 
                  }
      
      
      
                  
                      }
                .honeoi{
                  color: #ffffff;
                  font-weight: 500;
                  font-size: 20px;
                text-align: center !important;
                  text-shadow: 1px 1px 1px #000, 
                  3px 3px 5px rgb(0, 0, 0); 
                }
                @media screen and (min-width: 1000px) {
                    
                  .honeoi{
                    color: #ffffff;
                    font-weight: 500;
                    text-align: start !important;
                    font-size: 25px;
                  
                    text-shadow: 1px 1px 1px #000, 
                    3px 3px 5px rgb(0, 0, 0); 
                  }
                      }
                      .honeoiw{
                        color: #ffffff;
                        font-weight: 500;
                        font-size: 13px;
                      text-align: center !important;
                        text-shadow: 1px 1px 1px #000, 
                        3px 3px 5px rgb(0, 0, 0); 
                      }
                      @media screen and (min-width: 1000px) {
                          
                        .honeoiw{
                          color: #ffffff;
                          font-weight: 500;
                          text-align: start !important;
                          font-size: 16px;
                        
                          text-shadow: 1px 1px 1px #000, 
                          3px 3px 5px rgb(0, 0, 0); 
                        }
                            }
            


                .term_white_hair_under{
                  font-size: 11px;
                  text-align: center !important;
                }
                
                @media screen and (min-width: 1000px) {
                  
                  .term_white_hair_under{
                    font-size: 13px;
                    text-align: start !important;
                  }
                  }












                  .honeh{
                    color: #ffffff;
                    font-weight: 800;
                    font-size: 20px;
                  text-align: center !important;
                    text-shadow: 1px 1px 1px #000, 
                    3px 3px 5px rgb(0, 0, 0); 
                  }
                  @media screen and (min-width: 1000px) {
                      
                    .honeh{
                      color: #ffffff;
                      font-weight: 800;
                      text-align: center !important;
                      font-size: 29px;
                    
                      text-shadow: 1px 1px 1px #000, 
                      3px 3px 5px rgb(0, 0, 0); 
                    }
                        }
        
        
















                        .honehh{
                          color: #ffffff;
                          font-weight: 800;
                          font-size: 50px;
                        text-align: center !important;
                          text-shadow: 1px 1px 1px #ffffff, 
                          10px 10px 5px rgb(0, 0, 0); 
                        }
                        @media screen and (min-width: 1000px) {
                            
                          .honehh{
                            color: #ffffff;
                            font-weight: 800;
                            text-align: center !important;
                            font-size: 38px;
                          
                            text-shadow: 1px 1px 1px #000, 
                            3px 3px 5px rgb(0, 0, 0); 
                          }
                              }
              .thumb_image_home{
                width: 80%;
              }

              @media screen and (min-width: 1000px) {
                            
                .thumb_image_home{
width: 40%;
                }
                    }














                    
     .footer_logo_topu{
      width: 100%;
     }       


     @media screen and (min-width: 1000px) {
              
      .footer_logo_topu{
        width: 100%;
       } 
          }




















  .honeab{
            color: #ffffff;
            font-weight: 700;
            font-size: 60px;
            position: relative;
            top: 220px;
          text-align: center !important;
            text-shadow: 1px 1px 1px #000, 
            3px 3px 5px rgb(0, 0, 0); 
          }
          @media screen and (min-width: 1000px) {
              
            .honeab{
              color: #ffffff;
              font-weight: 800;
              text-align: start !important;
              font-size: 100px;
            position: relative;
            top: 70px;
              text-shadow: 1px 1px 1px #000, 
              3px 3px 5px rgb(0, 0, 0); 
            }
                }




                                            





                .MobileTestosterone_btn_grad{
                  text-align: center;
                  align-items: center;
                  justify-content: center;
                }



       





  .navdrops{
    color: #3cb1a0 !important;
  }




  /* Styles for mobile devices */
@media (max-width: 767px) {
  /* Your mobile styles here */

  .play_with_cards_male_female{
    position: relative;
    top: -90px;
  }




  .positions_of_customerservice{
    position: relative;
    bottom: 90px;
  }

  .positions_of_customerservice_image{
    position: relative;
    bottom: 50px;
    
  }
  .homecard_1{
    position: relative;
    left: 0px;
   }   
   .homecard_2{
    position: relative;
    right: 0px;
   }    

   
.certified_doctorro{
  position: relative;
  top: 10px;
        }


        .center_thumbd{
          justify-content: center;
          align-items: center;
          text-align: center;
          display: flex;
          position: relative;
        right: 10px;
        }


        .certified_doctoroo{
          position: relative;
          top: 10px;
                }
}

/* Styles for tablet devices */
@media (min-width: 768px) and (max-width: 1280px) {
  .homecard_1{
    position: relative;
    left: 0px;
   }  
   
   .homecard_2{
    position: relative;
    right: 0px;
   }   
   
.certified_doctorro{
  position: relative;
  top: 10px;
        }

        .center_thumbd{
          justify-content: center;
          align-items: center;
          text-align: center;
          display: flex;
          position: relative;
        right: 110px;
        }

        .certified_doctoroo{
          position: relative;
          top: 1px;
                }
}

/* Styles for desktop devices */
@media (min-width: 1300px) {
  .homecard_1{
    position: relative;
    left: 100px;
   }

   .homecard_2{
    position: relative;
    right: 100px;
   }   


   .certified_doctorro{
    position: relative;
    top: 110px;
  
  }


  .center_thumbd{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    position: relative;
  right: 200px;
  }


  .certified_doctoroo{
    position: relative;
    top: 80px;
  }
}
























@media screen and (min-width: 1000px) {

    

      }









      
    
        
              @media screen and (min-width: 1000px) {
                      
           
                    }
        



                    .btn-gradomf {
                      background-image: linear-gradient(to right, #3cb1a0 0%, #3CD3AD  51%, #4CB8C4  100%);
                      margin: 10px;
                      padding: 10px 45px;
                      text-align: center;
                      text-transform: uppercase;
                      transition: 0.5s;
                      background-size: 200% auto;
                      color: rgb(11, 11, 11); 
                      border: none !important;
                      font-weight: 700;           
                    font-size: 17px !important;
                      border-radius: 5px;
                      text-decoration: none !important;
                      display: block;
                    }
                    
                    .btn-gradomf:hover {
                      background-position: right center; /* change the direction of the change here */
                      color: #ffffff;
                      text-decoration: none;
                    }


    .male_page_button{
      position: relative;
      left: 0px;
    }                


    @media screen and (min-width: 1000px) {
      .male_page_button{
      position: relative;
      left: 40px;
      }                
                  
           
    }







    .honecontact{
      color: #000000;
      font-weight: 800;
      text-align: center !important;
      font-size: 30px;
    
      text-shadow: 1px 1px 1px #ffffff, 
      3px 3px 5px rgb(255, 255, 255); 
    }
    @media screen and (min-width: 1000px) {
        
      .honecontact{
        color: #000000;
        font-weight: 800;
        text-align: center !important;
        font-size: 70px;
      
        text-shadow: 1px 1px 1px #ffffff, 
        3px 3px 5px rgb(255, 255, 255); 
      }
          }



          .background_productPage{
            background-image: url('./Component//image/productpage.jpg');
            background-color: rgba(169, 169, 169, 0.6); /* 50% opacity black color overlay */
            background-blend-mode: multiply; /* blend mode to multiply the image and color */
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              height: 100%;
          }




          .head_of_productsP{
            font-size: 40px;
            font-weight: 600;
          }
          @media screen and (min-width: 1000px) {
                  
                          
            .head_of_productsP{
              font-size: 45px;
              font-weight: 700;
            }  
                }



                .head_of_productstt{
                  font-size: 14px;
                  font-weight: 600;
                  position: relative;
                 
                }
                @media screen and (min-width: 1000px) {
                        
                                
                  .head_of_productstt{
                    font-size: 30px;
                    font-weight: 700;
                    color: #808282;
                    position: relative;
                    left: 0px;
                  }  
                      }






                      .background_product_card_maleX{
                        background-color: #ffffff !important;
                        border: 1px solid #ffffff !important;
                      }

                   .buttonAligns{
                    align-items: center;
                    justify-content: center;
                    display: flex;
                   }   


                   @media screen and (min-width: 1000px) {
                  
                          
                    .buttonAligns{
                      align-items: end;
                      justify-content: end;
                      display: flex;
                     }   
  
                        }


                        .buttonAlignst{
                          align-items: center;
                          justify-content: center;
                          display: flex;
                         }   
      
      
                         @media screen and (min-width: 1000px) {
                        
                                
                          .buttonAlignst{
                            align-items: start;
                            justify-content: start;
                            display: flex;
                           }   
        
                              }



.centerall{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}










































































                              








                              .background_product_card_maleXG{
                                background-color:#e1e1e1 !important;
                                border: 1px solid #ffffff !important;
                              }
        

 .centeralldivofmale{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
 }                             










 


 .honeabs{
  color: #000000;
  font-weight: 700;
  font-size: 60px;
  position: relative;

text-align: center !important;
  text-shadow: 1px 1px 1px #000, 
  3px 3px 5px rgb(0, 0, 0); 
}
@media screen and (min-width: 1000px) {
    
  .honeabs{
    color: #000000;
    font-weight: 800;
    text-align: center !important;
    font-size: 100px;
  position: relative;

    text-shadow: 1px 1px 1px #000, 
    3px 3px 5px rgb(0, 0, 0); 
  }
      }




      .honere{
        color: #ffffff;
        font-weight: 700;
        font-size: 30px;
      text-align: center !important;
        text-shadow: 1px 1px 1px #000, 
        3px 3px 5px rgb(0, 0, 0); 
      }
      @media screen and (min-width: 1000px) {
          
        .honere{
          color: #ffffff;
          font-weight: 900;
          text-align: start !important;
          font-size: 70px;
         line-height: 100px !important;
          text-shadow: 1px 1px 1px #000, 
          3px 3px 5px rgb(0, 0, 0); 
        }
            }