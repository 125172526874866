:root {
    --bg-color: #0c0c0c;
    --primary-color: #0d0d0d;
    --secondary-color: #fff;
    --text-color: #fff;
    --text-color-2: #fff;
    --overlay-color: rgb(12 12 12 / 63%);
}

[data-theme="light"] {
    --bg-color: #ffffff;
    --primary-color: #ffffff;
    --secondary-color: #000;
    --text-color: #000;
    --text-color-2: #000;
    --overlay-color: rgb(255 255 255 / 70%);
}

html,



ul {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Marcellus;
}

a,
a:hover {
    color: var(--text-color);
}

p {
    word-break: break-word;
    hyphens: auto;
}

.ovhidden {
    overflow: hidden;
}

.text_2,
.text_2:hover {
    color: var(--text-color-2);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor__dot div {
    z-index: 999999 !important;
    
}

.cursor__dot div:last-child {
    
    filter: invert(1);
    background-color: var(--primary-color) !important;
}

.cursor__dot div:first-child {
    filter: invert(1);
    
    background-color: var(--overlay-color) !important;
}

.color_pr {
    color: var(--primary-color) !important;
}

.color_sec {
    color: var(--secondary-color);
}