.cards_item {
    display: flex;
    padding: 1rem;
    max-width: 800px;
    margin: 0 auto;
    background: #0910141e !important;
  }
  
  .card_image {
    max-height: 250px;
    margin-top: -14px;
    background: #0910141e !important;
    img {
      width: 100%;
      max-width: 400px;
      height: 100%;
      object-fit: cover;
      background: #0910141e !important;
    }
    
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100px;
      position: relative;
      top: -100px;
      
      background: linear-gradient(0deg, #091014 10%, rgba(0, 0, 0, 0) 100%);
    }
  }
  
  .cards {
    background-color: #091014 !important;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .card_content {
    padding: 1rem;
    z-index: 1;
    display: block;
  }
  
  .card_title {
    font-family: 'Lobster', cursive;
    font-size: 22px;
    line-height: 42px;
    margin-top: -50px;
    margin-bottom: 24px;
    text-shadow: black 1px 0 15px;
    color: white;
  }
  
  .card_text {
    color: #DDDACB;
    font-family: 'Raleway', sans-serif;
    font-weight: 200;
    p {
      max-width: 700px;
    }
  }
  
  .card_price {
    position: relative;
    top: 10px;
    right: 16px;
    text-align: right;
    color: white;
    font-family: "Raleway", sans-serif;
    height: 24px;
    font-size: 36px;
    font-weight: 400;
    text-shadow: black 3px 0px 6px;
  }
  
  #item_fig .card:after {
    content: "Seasonal";
    font-family: "Raleway", sans-serif;
    color: rgba(234, 225, 175, 0.8);
    font-weight: 600;
    // font-style: italic;
    text-transform: uppercase;
    display: block;
    width: 10ch;
    height: 1.5em;
    padding: 0.2em 0.4em;
    position: relative;
    bottom: calc(100% - 2.5em);
    left: 1em;
  }