.containers {

    border-radius: 1px;
   
    padding: 38px;  

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    text-align: center;
    border-radius: 10px;
    
  }
  
  .wrapper {
    width: 100%;
    height: 100%;
    
  }
  
  .banner-image {
    background-image: url(https://images.unsplash.com/photo-1641326201918-3cafc641038e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80);
    background-position: center;
    background-size: cover;
    height: 300px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0)
  }
  
  .h1{
    font-family: 'Righteous', sans-serif;
    color: rgba(255,255,255,0.98);
    text-transform: uppercase;
    font-size: 7rem !important;
  }
  
  .p {
    color: #000000;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .button-wrapper{
    margin-top: 18px;
  }
  
  .btn {
    border: none;
    padding: 12px 24px;
    border-radius: 24px;
    font-size: 12px;
    font-size: 0.8rem;  
    letter-spacing: 2px;  
    cursor: pointer;
  }
  
  .btn + .btn {
    margin-left: 10px;
  }
  
  .outline {
    background: transparent;
    color: rgba(0, 212, 255, 0.9);
    border: 1px solid rgba(0, 212, 255, 0.6);
    transition: all .3s ease;
    
  }
  
  .outline:hover{
    transform: scale(1.125);
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9);
    transition: all .3s ease;  
  }
  
  .fill {
    background: rgba(0, 212, 255, 0.9);
    color: rgba(255,255,255,0.95);
    filter: drop-shadow(0);
    font-weight: bold;
    transition: all .3s ease; 
  }
  
  .fill:hover{
    transform: scale(1.125);  
    border-color: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0 90px 5px rgba(0, 0, 0, 0.856));
    transition: all .3s ease;    
  }