.video-background {
    position: relative;
    height: 100vh;

  }
  
  .video-background video {
   
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  }
  
  .text-overlay {
    position: absolute;
   
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: white;
    text-align: center;
  }
  
  .text-overlay h1 {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .text-overlay p {
    font-size: 2rem;
    line-height: 1.5;
    max-width: 80%;
    margin: 0 auto;
  }
  
  @media only screen and (max-width: 768px) {
    .text-overlay h1 {
      font-size: 3rem;
    }
  
    .text-overlay p {
      font-size: 1.5rem;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .text-overlay h1 {
      font-size: 2rem;
    }
  
    .text-overlay p {
      font-size: 1rem;
      max-width: 100%;
    }
  }
  
  .contactus_footer{
    color: #fff;
    font-size: 15px;
  }

  @media screen and (min-width: 1000px) {
              
    .contactus_footer{
      color: #fff;
      font-size: 20px;
    }
        }

  .contactus_footerz{
    color: #ffffff96;
    font-size: 10px;
  }

  @media screen and (min-width: 1000px) {
              
    .contactus_footerz{
      color: #ffffff96;
      font-size: 15px;
    }
        }


  .contactus_footerv{
    color: #63fff2c4;
    font-size: 20px;
  }

  @media screen and (min-width: 1000px) {
              
    .contactus_footerv{
      color: #63fff2c4;
      font-size: 40px;
    }
        }
